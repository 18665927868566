<template lang="pug">
    div.app-container
        el-form(ref="dataForm"
                :rules="rules"
                :model="dataForm"
                status-icon
                label-position="left"
                label-width="100px"
                ).change-password
            el-form-item(label="原密码" prop="oldPassword")
                el-input(v-model="dataForm.oldPassword" show-password type="password")
            el-form-item(label="新密码" prop="newPassword")
                el-input(v-model="dataForm.newPassword" type="password" auto-complete="off" show-password)
            el-form-item(label="确认密码" prop="newPassword2")
                el-input(v-model="dataForm.newPassword2" type="password" auto-complete="off" show-password)

        div.action-button
            el-button(@click="cancel") 取消
            el-button( @click="change" type="primary") 确定
    
</template>

<script>
import { changePassword } from "@/api/profile";

export default {
  name: "ChangePassword",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.dataForm.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      dataForm: {
        oldPassword: "",
        newPassword: "",
        newPassword2: ""
      },
      rules: {
        oldPassword: [
          { required: true, message: "旧密码不能为空", trigger: "blur" }
        ],
        newPassword: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
          { min: 6, message: "新密码必须大于6", trigger: "blur" },
          { validator: validatePass, trigger: "blur" }
        ],
        newPassword2: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { min: 6, message: "新密码必须大于6", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    cancel() {
      this.dataForm = {
        oldPassword: "",
        newPassword: "",
        newPassword2: ""
      };
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    change() {
      this.$refs["dataForm"].validate(async valid => {
        if (!valid) {
          return;
        }
        await changePassword(this.dataForm);

        this.$notify.success({
          title: "成功",
          message: "修改密码成功,请重新登录",
          onClose: () => {
            this.$store.dispatch("Logout").then(() => {
              location.reload();
            });
          }
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.change-password {
  width: 400px;
  margin-left: 50px;
}

.action-button {
  margin-left: 100px;
}
</style>
